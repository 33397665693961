import { useEffect } from 'react';

const UTMLinkHandler = () => {
  useEffect(() => {
    function getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift();
    }

    function fillFormFields() {
      const parameters = [
        'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content',
        'gclid', 'fbclid', 'dclid', 'msclkid', 'referrer',
      ];

      parameters.forEach(param => {
        const paramValue = getCookie(param);
        if (paramValue) {
          const field = document.querySelector<HTMLInputElement>(`#form-field-${param}`);
          if (field) {
            field.value = paramValue;
          }
        }
      });
    }

    function addParametersToLinks() {
      const parameters = [
        'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content',
        'gclid', 'fbclid', 'dclid', 'msclkid', 'referrer',
      ];

      const links = document.querySelectorAll<HTMLAnchorElement>('a');

      links.forEach(link => {
        if (link.hostname !== window.location.hostname || link.classList.contains('external-link')) {
          let url = new URL(link.href);
          let hasParams = false;

          parameters.forEach(param => {
            const paramValue = getCookie(param);
            if (paramValue) {
              url.searchParams.set(param, paramValue);
              hasParams = true;
            }
          });

          if (hasParams) {
            link.href = url.toString();
          }
        }
      });
    }

    document.addEventListener('DOMContentLoaded', fillFormFields);
    document.addEventListener('DOMContentLoaded', addParametersToLinks);

    return () => {
      document.removeEventListener('DOMContentLoaded', fillFormFields);
      document.removeEventListener('DOMContentLoaded', addParametersToLinks);
    };
  }, []);

  return null;
};

export default UTMLinkHandler;
