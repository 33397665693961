import { useEffect } from 'react';

const UTMHandler = () => {
  useEffect(() => {
    function getParameterByName(name: string) {
      const url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function setCookie(name: string, value: string, days: number) {
      const d = new Date();
      d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = `${name}=${value};${expires};path=/`;
    }

    function getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift();
    }

    function captureUTMParameters() {
      const parameters = [
        'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content',
        'gclid', 'fbclid', 'dclid', 'msclkid', 'referrer',
      ];

      parameters.forEach(param => {
        const paramValue = getParameterByName(param);
        if (paramValue) {
          if (param === 'fbclid') {
            if (!getCookie('_fbc')) {
              const fbcValue = `fb.1.${Math.floor(Date.now() / 1000)}.${paramValue}`;
              setCookie('_fbc', fbcValue, 30);
            }
          } else {
            setCookie(param, paramValue, 30);
          }
        }
      });
    }

    function fillFormFields() {
      const fields = [
        'name', 'email', 'phone', 'utm_source', 'utm_medium', 'utm_campaign',
        'utm_term', 'utm_content', 'gclid', 'fbclid', 'dclid', 'msclkid', 'referrer',
      ];

      fields.forEach(field => {
        const value = getCookie(field);
        if (value) {
          const input = document.querySelector<HTMLInputElement>(`#form-field-${field}`);
          if (input) {
            input.value = value;
          }
        }
      });
    }

    document.addEventListener('DOMContentLoaded', captureUTMParameters);
    document.addEventListener('DOMContentLoaded', fillFormFields);

    return () => {
      document.removeEventListener('DOMContentLoaded', captureUTMParameters);
      document.removeEventListener('DOMContentLoaded', fillFormFields);
    };
  }, []);

  return null;
};

export default UTMHandler;
